// eslint-disable-next-line no-redeclare,no-unused-vars
function buildConfigSwitch(cont) {
  branches.push('develop');

  var settings = {
    field: 'select',
    placeholder: 'Configuration',
    save: function (value) {
      config = value;
      if (value) localStorage.setItem('configuration', value);
      else localStorage.removeItem('configuration');
      ajax({
        object: 'setCookie',
        post: true,
        background: true,
        send: {key: 'configuration', value: value},
        callback: function () {
          ajax({
            object: 'settings',
            send: {func: 'settingsUpdate'},
          });
        },
      });
    },
    field2: 'button',
    iconBtnField2: 'sync',
    tipBtnField2: 'Configuration aktualisieren',
    funcField2: function () {
      ajax({
        object: 'settings',
        send: {func: 'settingsUpdate'},
      });
    },
    field3: 'button',
    iconBtnField3: 'eye',
    tipBtnField3: 'Configuration anzeigen',
    funcField3: function () {
      showSettings();
    },
    field4: 'button',
    iconBtnField4: 'plus',
    tipBtnField4: 'Branch anlegen',
    funcField4: function () {
      var name = 'branch' + rand();
      buildPromptPopup([{label: 'Branch Name', value: name}], function (name) {
        if (!name) return;
        name = name.replace(' ', '-').replace(/[^a-z0-9\\-]/gi, '');
        if (inArray(name, branches)) {
          alert('Branch existiert bereits');
          return false;
        }
        ajax({
          object: 'settings',
          send: {func: 'saveConfigBranch', branch: name},
        });
        settings.save(name);
      });
    },
    field5: 'button',
    iconBtnField5: 'save',
    tipBtnField5: 'Branch mergen',
    funcField5: function () {
      if (!config) return;
      var fields = [];
      fields.push({
        key: 'branchSource',
        label: 'branch_source',
        field: 'read',
        value: config,
      });
      fields.push({
        key: 'branchTarget',
        label: 'branch_target',
        field: 'select',
        values: arrayMerge(branches, ['sandbox']),
        value: 'develop',
      });
      fields.push({
        key: 'identSource',
        label: 'ident_source',
        field: 'select',
        values: arrayMerge(branches, [client]),
        value: config,
      });
      fields.push({
        key: 'identTarget',
        label: 'ident_target',
        field: 'select',
        values: arrayMerge(branches, [client, 'kitchenIndustry', 'global']),
        value: 'kitchenIndustry',
      });
      fields.push({key: 'commitMessage', label: 'commit_message', value: ''});
      buildPromptPopup(
        fields,
        function (
          branchSource,
          branchTarget,
          identSource,
          identTarget,
          commitMessage
        ) {
          ajax({
            object: 'settings',
            send: {
              func: 'mergeConfig',
              branchSource: branchSource,
              branchTarget: branchTarget,
              identSource: identSource,
              identTarget: identTarget,
              commitMessage: commitMessage,
            },
          });
        }
      );
    },
    field6: 'button',
    iconBtnField6: 'trash',
    tipBtnField6: 'Branch löschen',
    funcField6: function () {
      if (!config) return;
      buildConfirmPopup(
        'Den Branch ' + config + ' wirklich löschen?',
        function (result) {
          if (!result) return;
          ajax({
            object: 'settings',
            send: {func: 'deleteConfigBranch', branch: config},
          });
          settings.save('develop');
        }
      );
    },
  };
  if (window.branches) settings.values = branches;

  var line = buildFormLine(
    cont,
    {
      settingsNew: settings,
      value: config,
      classLine: 'me-2',
    },
    'config'
  );

  // add context menu
  contextMenu(
    line.els[2],
    {
      branch: 'branch',
      merged: 'merge',
      client: 'client',
      industry: 'industry',
      global: 'global',
    },
    function (type) {
      showSettings(type);
    }
  );

  // config switch
  setInterval(function () {
    var configCurrent = localStorage.getItem('configuration');
    if (!configCurrent && !config) return;
    if (configCurrent != config) {
      config = configCurrent;
      line.element.val(config);
      ajax({
        object: 'settings',
        send: {func: 'settingsUpdate'},
      });
    }
  }, 3000);

  return line;
}
